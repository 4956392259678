import React from "react"
import cx from "classnames"
import { useStaticQuery, graphql, Link } from "gatsby"
import Container from "components/Container"
import styles from "./Footer.module.css"
import * as Icons from "components/Icons"
import HubspotForm from "components/HubspotForm"

const icons = {
  twitter: Icons.Twitter,
  linkedin: Icons.Linkedin,
  github: Icons.Github,
}

export const Footer = ({ location, data, layout }) => {
  const { socials } = data.markdownRemark.frontmatter

  const whatwedonav = [
    { 
      heading: "Getting Started",
      data: data.gettingstarted.edges,
    },
    { 
      heading: "Accelerating Success",
      data: data.acceleratingsuccess.edges
    },
    { 
      heading: "Optimizing Productivity",
      data: data.optimizingproductivity.edges
    }
  ]

  const industriesnav = [
    { data: data.industries.edges },
    { data: data.industries2.edges },
    { data: data.industries3.edges },
  ]

  const partnersnav = [
    { 
      links: [
        { 
          path: '/aws-premier-partner',
          label: 'Premier Amazon Web Services Partner'
        },
        {
          path: '/google-cloud-premier-partner',
          label: 'Google Cloud Premier Partner'
        },
        { 
          path: '/azure-consulting',
          label: 'Microsoft Solutions Partner'
        }
      ]
    },
    { 
      links: [
        { 
          path: '/aws-map',
          label: 'Migration Acceleration Program'
        },
        { 
          path: '/oracle-aws-migration',
          label: 'Oracle Database Migration'
        }
      ]
    }
  ]

  const insightsnav = [
    { 
      path: '/blog',
      label: 'Blog'
    },
    { 
      path: '/resources',
      label: 'Resources'
    },
    {
      path: '/events',
      label: 'Events'
    },
    { 
      path: '/success-stories',
      label: 'Success Stories'
    },
  ]

  const aboutusnav = [
    { 
      path: '/about-us',
      label: 'About OpsGuru'
    },
    {
      path: '/team-page',
      label: 'Leadership Team'
    },
    { 
      path: '/locations',
      label: 'Locations'
    },
    { 
      path: '/careers',
      label: 'Careers'
    },
  ]

  return (
    <>
      {layout === 'ppc' || layout === 'ppcwithheader' || layout === 'ppcformbanner' ? (
        <footer className={styles.ppcFooter}>
          <Container className={cx(styles.wrap, styles.ppcFooterWrap)}>
            <div className={styles.ppcFooterLogo}>
              <Link className={cx(styles.logo, styles.logoPPC)} to="/">
                OpsGuru
                {Icons.Logo}
              </Link>
            </div>
            <ul className={styles.ppcContactList}>
              <li>
                <a href="mailto:info@opsguru.io" className={styles.ppcFooterEmail}>
                  info@opsguru.io
                </a>
              </li>
              {location.pathname.replace(/\/$/, "") !== '/google-cloud-israel' ? (
                <>
                  <li>
                    <a href="tel:+17788002468" className={styles.ppcFooterPhone}>
                      +1 778 800 2468
                    </a>
                  </li>
                  <li>
                    <span className={styles.ppcFooterLocation}>
                      Vancouver, Toronto, Saint John, Israel, Romania
                    </span>
                  </li>
                </>
              ) : (
                <li>
                  <span className={styles.ppcFooterLocation}>
                    Rokach Blvd. 101 Pavilion 2, Tel Aviv Convention Center, Tel-Aviv 6970097
                  </span>
                </li>
              )}
            </ul>
          </Container>
          <Container className={cx(styles.wrap, styles.copyright, styles.ppcCopyright)}>
             <span>© {new Date().getFullYear()} OpsGuru | <Link to="/privacy-policy">Privacy Policy</Link></span>
          </Container>
        </footer>
      ) : (
        <footer className={styles.root}>
          <Container className={cx(styles.wrap, styles.footerWrap)}>
            <div className={styles.footerWrapper}>
              <div className={styles.footerContact}>
                <Link to="/" className={cx(styles.logo, styles.footerLogo)}>
                  OpsGuru
                  {Icons.Logo}
                </Link>
                <Link to="/contact" className={styles.contactus}>
                  Contact us
                </Link>
                <div className={styles.footerContactMain}>
                  <a href="mailto:info@opsguru.io" className={styles.ppcFooterEmail}>
                    info@opsguru.io
                  </a>
                  <a href="tel:+17788002468" className={styles.ppcFooterPhone}>
                    +1 778 800 2468
                  </a>
                  <span className={styles.ppcFooterLocation}>
                    Vancouver, Toronto, Saint John, Israel, Romania
                  </span>
                </div>
                <div className={styles.footerContactSocial}>
                  {Object.keys(socials).map((social) => (
                    <a key={social} href={socials[social]} target="_blank" rel="noopener noreferrer">
                      {social}
                      {icons[social]}
                    </a>
                  ))}
                </div>
                <div className={styles.subscribeFormContainer}>
                  <h2>Subscribe to our newsletter!</h2>
                  <HubspotForm id="f0a55e04-975b-4b11-8c4e-889fe1163dcb" portalId="6237410" />
                </div>
              </div>
              <nav className={styles.footerNavMid}>
                <h2>What We Do</h2>
                <div className={styles.footerNavWrap}>
                  {whatwedonav.map((group) => (
                    <ul>
                      <li>{group.heading}</li>
                      {group.data.map((item) => (
                        <li key={item.node.frontmatter.url}>
                          <Link to={`/${item.node.frontmatter.url}`}>
                            <span>{item.node.frontmatter.navTitle ? item.node.frontmatter.navTitle : item.node.frontmatter.title}</span>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  ))}
                </div>
                <div className={styles.footerNavWrap}>
                  {partnersnav.map((group) => (
                    <div className={cx(styles.footerNavItem, styles.footerNavPartners)}>
                      <h2>Partners</h2>
                      <ul>
                        {group.links.map((link) => (
                          <li key={link.path}>
                            <Link to={link.path}>
                              <span>{link.label}</span>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
              </nav>
              <nav className={styles.footerNavSide}>
                <div className={cx(styles.footerNavWrap, styles.footerNavWrapTop)}>
                  <div className={styles.footerNavItem}>
                    <h2>Insights</h2>
                    <ul>
                      {insightsnav.map((link) => (
                        <li key={link.path}>
                          <Link to={link.path}>
                            <span>{link.label}</span>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className={styles.footerNavItem}>
                    <h2>About Us</h2>
                    <ul>
                      {aboutusnav.map((link) => (
                        <li key={link.path}>
                          <Link to={link.path}>
                            <span>{link.label}</span>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className={styles.footerNavWrap}>
                  <div className={styles.footerNavItem}>
                    <div className={styles.footerCarbon60Logo}>
                      <a target="_blank" href="https://www.carbon60.com/" rel="noreferrer">
                        {Icons.LogoCarbon60}
                      </a>
                      <p>For managed cloud services, visit our parent company, <a target="_blank" href="https://www.carbon60.com/" rel="noreferrer">Carbon60</a>.</p>
                    </div>
                  </div>
                </div>
              </nav>
            </div>
          </Container>

          <div className={styles.copyright}>
            <Container className={styles.wrap}>
               <span>© {new Date().getFullYear()} OpsGuru <span>|</span></span>
               <span>
                 <Link to="/privacy-policy">
                     Privacy Policy
                 </Link>
               </span>
            </Container>
          </div>
        </footer>
      )}
    </>
  )
}

export const FooterWrapper = (props) => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      site {
        siteMetadata {
          title
          navigation {
            label
            path
            links {
              label
              path
            }
          }
        }
      }
      markdownRemark(frontmatter: { templateKey: { eq: "main" } }) {
        frontmatter {
          socials {
            linkedin
            github
          }
        }
      }
      gettingstarted: allMarkdownRemark(
        sort: { fields: [frontmatter___menuOrder], order: ASC }
        filter: { 
          frontmatter: { 
            templateKey: { eq: "whatwedo" },
            category: { eq: "Getting Started" },
            menuOrder: { ne: null },
            url: { ne: "devOps-enablement" }
          } 
        }
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              url
              title
              navTitle
            }
          }
        }
      }
      acceleratingsuccess: allMarkdownRemark(
        sort: { fields: [frontmatter___menuOrder], order: ASC }
        filter: { 
          frontmatter: { 
            templateKey: { eq: "whatwedo" },
            category: { eq: "Accelerating Success" },
            menuOrder: { ne: null },
            url: { ne: "aws-map" }
          } 
        }
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              url
              title
              navTitle
            }
          }
        }
      }
      optimizingproductivity: allMarkdownRemark(
        sort: { fields: [frontmatter___menuOrder], order: ASC }
        filter: { 
          frontmatter: { 
            templateKey: { eq: "whatwedo" },
            category: { eq: "Optimizing Productivity" },
            menuOrder: { ne: null },
            url: { ne: "cloud-spend-optimization" }
          } 
        }
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              url
              title
              navTitle
            }
          }
        }
      }
      partners: allMarkdownRemark(
        sort: { fields: [frontmatter___menuOrder], order: ASC }
        filter: { frontmatter: { templateKey: { eq: "partner" }, menuOrder: { ne: null } } }
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              url
              title
              menuImage {
                extension
                publicURL
                childImageSharp {
                  fluid(maxWidth: 472, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
              menuButtonLabel
            }
          }
        }
      }
      industries: allMarkdownRemark(
        sort: { fields: [frontmatter___menuOrder], order: ASC }
        filter: { frontmatter: { templateKey: { eq: "industries" }, menuOrder: { ne: null } } }
        limit: 5
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              url
              title
              navTitle
            }
          }
        }
      }
      industries2: allMarkdownRemark(
        sort: { fields: [frontmatter___menuOrder], order: ASC }
        filter: { frontmatter: { templateKey: { eq: "industries" }, menuOrder: { ne: null } } }
        limit: 5
        skip: 5
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              url
              title
              navTitle
            }
          }
        }
      }
      industries3: allMarkdownRemark(
        sort: { fields: [frontmatter___menuOrder], order: ASC }
        filter: { frontmatter: { templateKey: { eq: "industries" }, menuOrder: { ne: null } } }
        limit: 5
        skip: 10
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              url
              title
              navTitle
            }
          }
        }
      }
    }
  `)
  return <Footer data={data} {...props} />
}

export default FooterWrapper
